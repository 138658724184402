$color-primary-0: #DF73A4;
$color-primary-1: #FCF1F6;
$color-primary-2: #EFB1CD;
$color-primary-3: #CA417F;
$color-primary-4: #B20F59;
$color-secondary-0: #C0EF7B;
$color-secondary-1: #F9FDF3;
$color-secondary-2: #DDF7B7;
$color-secondary-3: #9FDC47;
$color-secondary-4: #7AC311;
$color-white: #fff;
$color-black: #000;
$primary: $color-primary-0;
$secondary: $color-secondary-0;
$body-bg: $color-primary-1;
$body-bg-secondary: $color-secondary-1;
$footer-bg: $color-primary-2;

// $success:       $green;
// $info:          $cyan;
// $warning:       $yellow;
// $danger:        $red;
// $light:         $gray-100;
// $dark:          $gray-900;

@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons.css";
@import url("https://fonts.googleapis.com/css2?family=REM:ital,wght@0,100;0,300;0,400;0,600;0,800;1,100;1,300;1,400;1,600;1,800&display=swap");
@import "./studioinfo";
@import "./servicebox";

.superLoginCont {
    margin-top: 20rem;
}

a.navbar-brand-logo,
a.navbar-brand-logo:hover,
a.navbar-brand-logo:active,
a.navbar-brand-logo:visited {
    text-decoration: none;
}

.navbar {
    background: $color-black;
    text-underline-offset: 1rem;

    .active {
        font-weight: 700;
    }

    .nav-item {
        position: relative;
    }

    .nav-item:hover {
        color: $color-secondary-1;
    }

    .nav-item::after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: $color-secondary-1;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }

    .nav-item:hover::after,
    .nav-item.active::after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }

    .dropdown-menu .dropdown-item {
        font-size: 1.2rem;
        color: rgba($body-bg-secondary, 0.55);
    }

    .dropdown-item:hover,
    .dropdown-item:focus {
        background: $color-black;
        color: $color-secondary-1;
    }
}

.navbar-brand,
div.logo {
    display: flex;
    flex-direction: row;
    font-family: "Ubuntu", REM, sans-serif;
    margin-right: 1.5rem;

    div {
        display: flex;
        flex-direction: column;

        span {
            display: flex;
        }
    }

    img {
        display: flex;
        flex-direction: column;

        height: 3rem;
        margin-right: 0.9rem;
    }


    .logo-text {
        justify-content: center;
    }

    .logo-line1 {
        font-family: "Ubuntu", REM, sans-serif;
        font-weight: 800;
        font-size: 1.2rem;
    }

    .logo-line2 {
        font-family: "Ubuntu", REM, sans-serif;
        font-weight: 100;
        font-size: 0.9rem;
        line-height: 0.9;
        margin-bottom: 0.5rem;
    }
}

.hero-img {
    position: relative;
    width: 100vw;
    height: 70vh;
    font-family: "Ubuntu", REM, sans-serif;
    background: url("../img/hero-img2.jpg") no-repeat center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: end;
    flex-direction: column;

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(2, 0, 36);
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.7) 0%, rgba($body-bg-secondary, 1) 100%);
        z-index: 1;
    }

    .right-info {
        z-index: 2;
    }

    .logo {
        padding: 0.8rem;
        margin-right: 2rem;

        img {
            width: 3rem;
            height: 6.25rem;
            margin-right: 1.2rem;
        }

        .logo-line1 {
            font-size: 1.5rem;
        }

        .logo-line2 {
            font-size: 1.1rem;
            line-height: 0.9;
        }
    }

    .place {
        font-size: 1.3rem;
        padding: 2rem;
        padding-top: 0;
        margin-left: 3rem;
    }

    a {
        text-decoration: none;
    }
}

.dropdown-menu {
    border-radius: 0;
    color: $color-secondary-1;
    background: $color-black;

    .dropdown-item {
        color: $color-secondary-1;
    }
}

.navbar .nav-link {
    font-family: "Ubuntu", REM, sans-serif;
    font-size: 1.2rem;
}

@media (min-width: 576px) {
    .hero-img {
        .logo {
            margin-right: 4rem;

            img {
                width: 4rem;
                height: 8.3rem;
                margin-right: 1.2rem;
            }

            .logo-line1 {
                font-size: 1.7rem;
            }

            .logo-line2 {
                font-size: 1.3rem;
                line-height: 0.9;
            }
        }

        .place {
            font-size: 1.3rem;
        }
    }
}

@media (min-width: 768px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .hero-img {
        .logo {
            margin-right: 8rem;

            img {
                width: 8rem;
                height: 16.7rem;
                margin-right: 1.2rem;
            }

            .logo-line1 {
                font-size: 2.5rem;
            }

            .logo-line2 {
                font-size: 1.7rem;
                line-height: 0.9;
            }
        }

        .place {
            font-size: 2.5rem;
        }
    }
}

@media (min-width: 1600px) {
    .hero-img {
        .overlay {
            background: rgb(2, 0, 36);
            background: linear-gradient(90deg, rgba(255, 255, 255, 0%) 0%, rgba($body-bg-secondary, 1) 100%);
        }
    }
}

.content-block {
    background: $body-bg;
    padding-top: 2rem;

    &:nth-of-type(even) .card {
        background: $body-bg-secondary;
    }

    &:nth-of-type(odd) {
        background: $body-bg-secondary;
    }
}

body {
    min-height: 40rem;
    min-width: 358px;
    padding-top: 4.5rem;
}

nav {
    min-width: 358px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Ubuntu", REM, sans-serif;
}

.maincontent {
    min-height: 80vh;

    .pagetop {
        font-size: 2rem;
        text-align: center;
        min-height: 3rem;
        padding: 2rem;
        background: $color-primary-3;
        color: white;
    }

    h3 {
        font-size: 115%;
        text-align: center;
    }
}

.gallery {
    margin-bottom: 3em;
    text-align: center;

    a {
        margin: 0.3rem;
        display: inline-block;
    }
}

.bg-footer {
    background: $footer-bg;
    color: $color-black;
}

.servicecard {
    height: 100%;
}

.gallery-wrapper {
    h3 {
        font-size: 2rem;
    }

    .content-block {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

// make anchors work with fixed navbar
:target::before {
    content: '';
    display: block;
    height: 80px;
    /* Adjust this value to match your navbar's height */
    margin-top: -80px;
    /* Negative value of the height */
    visibility: hidden;
}

form label {
    background: transparent;

    &::after {
        background-color: transparent !important;
    }
}

#kontakt-success,
#kontakt-error {
    display: none;
}

.salon-gallery h3 {
    font-size: 1.9rem;
}

.more-info-link {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.cenik table td.text-end {
    min-width: 10em;
}

.static-page {

    h1,
    h2,
    h3 {
        margin-top: 3rem;
        margin-bottom: 3rem;
        text-align: left;
    }

    h2 {
        color: #ca417f;
        font-weight: 900;
    }

    h3 {
        color: #ca417f;
    }
}
