.servicebox {
    .servicecard {
        height: 15rem;
        margin: 1rem 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;

        &.card {
            background-repeat: no-repeat!important;
            background-position: center!important;
            background-size: cover!important;
        }

        .card-body {
            display: flex;
            align-items: center;
            align-content: center;
            flex-wrap: wrap;

            background: rgba(0,0,0,0.6);

            h3 {
                color: white;
                font-size: 1.6rem;
                text-align: center;
                margin: auto;
            }

            .btn-wrap {
                width: 100%;
                text-align: center;
                margin-top: 1.5rem;

                .btn {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    a, a:hover, a:active, a:visited {
        text-decoration: none;
    }

    a:hover {
        .servicecard {
            transform: scale(1.05);
        }
    }
}
