.studioinfo {
    overflow: hidden;

    .img {
        text-align: center;

        img {
            width: 100%;
            opacity: 0;
            z-index: 1;
            transform: translateX(40rem);
            transition: all 1s;
        }
    }

    &.studioinfo-right {
        .img img {
            transform: translateX(-40rem);
        }
    }

    .visible .img img {
        opacity: 1;
        transform: translateX(0rem);
    }

    h2 {
        font-weight: 900;
    }

    h3 {
        font-size: calc(1.0rem + .6vw);
    }

    h6 {
        font-weight: bold;
    }
}

@media (min-width: 992px) {
    .studioinfo {
        .row {
            align-items: center;
            padding-bottom: 2rem;
        }

        .scroll-spy {
            padding: 0;
        }

        .infocard {
            text-align: right;
            z-index: 2;
            margin: 0 1rem;

            .card-body {
                padding: 1.5rem;
            }

            .card-title {
                margin: 0 1rem;
            }

            div.card-title {
                margin-top: 0.5rem;
                h3 {
                    font-size: 1.3rem;
                    text-align: right;
                }
            }

            .card-text {
                margin: 0 1rem;
                margin-top: 2rem;
            }
        }

        .img {
            height: 18rem;
            text-align: left;

            img {
                width: auto;
                height: 18rem;
                opacity: 0;
                z-index: 1;
                transform: translateX(40rem);
                transition: all 1s;
            }
        }

        .visible .img img {
            transform: translateX(-2.5rem);
            opacity: 1;
        }

        &.studioinfo-right {
            .row {
                flex-direction: row-reverse;

            }

            form {
                .row {
                    flex-direction: row;
                }
            }

            .infocard {
                text-align: left;

                div.card-title {
                    h3 {
                        text-align: left;
                    }
                }
            }

            .img {
                text-align: right;

                img {
                    transform: translateX(-40rem);
                }

            }

            .visible .img img {
                transform: translateX(2.5rem);
            }
        }
    }
}
